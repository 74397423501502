<template>
  <div class="box">
    <div class="box-title">
      <span class="h3">推荐项目</span
      ><em><router-link to="/project">更多..</router-link></em>
    </div>
    <div class="list">
      <div class="item" v-for="item in lists" :key="item.id">
        <router-link :to="`/project/${item.id}`" class="poster">
          <img :src="item.coverImage" />
        </router-link>

        <div class="content">
          <div class="info">
            <div class="title">
              <div class="inner">
                <div><router-link :to="`/project/${item.id}`">{{ item.title }}</router-link></div>
                <div class="avator">
                  <svg-icon icon="icon_avator"></svg-icon
                  ><span>{{ item.userNickname }}</span>
                </div>
                <div>
                  <em>{{ item.province }}{{ item.city }}</em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { useStoreData } from '@/hooks/index'
const store = useStore()
const lists = useStoreData([], () => {
  return store.state.suggest.projects
})

onMounted(() => {
  store.dispatch('suggest/checkSuggest')
})
</script>
<style lang="scss" scoped>
.box {
  background: white;
  width: 260px;
  padding-bottom: 30px;
  border-radius: 10px;
  box-shadow: 1px 1px 11px rgb(212 212 212 / 25%);
  .box-title {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    font-weight: bold;
    padding: 15px;
    padding-bottom: 0;
    .h3 {
      position: relative;
      &::before {
        content: ' ';
        background: #fc9507;
        width: 5px;
        height: 32px;
        display: block;
        top: 0;
        left: -15px;
        position: absolute;
      }
    }

    em {
      font-weight: normal;
      font-size: 14px;
      color: #666;
      font-style: normal;
    }
    a {
      text-decoration: none;
    }
  }

  .item {
    display: flex;
    text-align: left;
    padding-bottom: 5px;
    padding: 15px;
    .content {
      flex: 1;
      margin-left: 10px;
    }
    .inner {
      line-height: 18px;
    }
    .avator{
      display: flex;
      align-items: center;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      color: #666;
      margin-top: 4px;
      .svg-icon{
        width:16px;height:16px;margin-right: 5px;
      }
    }
    .info {
      em {
        font-size: 12px;
        color: #ccc;
        font-style: normal;
      }
      .time {
        display: inline-block;
        background-color: #0093ef;
        color: white;
        font-size: 12px;
        padding: 0 4px;
        border-radius: 2px;
      }
      .title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: bold;
      }
      .desc {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 显示的行数 */
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5em; /* 每行的高度 */
        max-height: 3em; /* line-height * 行数 */
      }
      .meta {
        color: #999;
        font-size: 14px;
        text-align: right;
        .meta-title {
          display: flex;
          align-items: center;
        }
        .svg-icon {
          margin-left: 5px;
        }
      }
    }
  }
  .poster {
    width: 60px;
    height: 60px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
