<template>
  <div class="page-wrap">
    <div class="section-wrap">
      <div class="wrapper-left">
        <breadcrumbs :crumbs="pageData.crumbs"></breadcrumbs>
        <div class="wrapper-content">
          <div class="wrapper-list">
            <div class="project-info">
              <div class="info">
                <div class="title">
                  <h3>
                    <span
                      class="title-line"
                      :title="state.title || state.investIntent"
                      >{{ state.title || state.investIntent }}</span
                    >
                  </h3>
                </div>
              </div>
              <div class="project-detail">
                <YJSL class="jysl" :favorited="state.favoritesFlag" :targetId="state.id" :favoritesType="state.favoritesType"></YJSL>
                <div>
                  来自：<a
                    class="tag"
                    :href="state.belongUnitWebsite"
                    target="_blank"
                    >{{ state.belongUnit }}</a
                  >
                </div>
              </div>
              <div class="poster" style="margin-top: 30px">
                <el-carousel height="240px" v-if="state.image">
                  <el-carousel-item
                    v-for="item in state.image.split(',')"
                    :key="item"
                  >
                    <div class="wrap">
                      <img :src="item" />
                    </div>
                  </el-carousel-item>
                </el-carousel>
                <video :src="state.video" v-else-if="state.video"></video>
                <img :src="state.partnership || state.coverImage" v-else />
              </div>
              <div class="desc">
                {{ state.article }}
              </div>
            </div>
            <div class="project-detail">
              <div class="comment" style="margin-top: 40px">
                <div class="block">
                  <div class="tag">评论</div>
                  <div class="desc" style="padding-top: 15px">
                    <Comment
                      :id="pageData.id"
                      :type="pageData.commentType"
                    ></Comment>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="wrapper-right">
            <QuickPublishProject></QuickPublishProject>
            <BlockSuggestProject class="box-suggest"></BlockSuggestProject>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, defineAsyncComponent, computed, defineProps } from 'vue'
import { useRoute } from 'vue-router'
import { Options, Vue } from 'vue-class-component'
import ItemProject from '@/components/ItemProject.vue'
import QuickPublishProject from '@/components/QuickPublishProject.vue'
import BlockSuggestProject from '@/components/BlockSuggestProject.vue'
import YJSL from '@/components/YJSL.vue'
import Comment from '@/components/Comment.vue'
import PayItem from '@/components/PayItem.vue'
import { Api } from '@/services/http'

const props = defineProps({
  type: {
    type: String,
    required: false,
  },
})
const rate = ref(0)
let pageData = reactive({
  id: '',
  commentType: 1,
  joinType:1,
  favoritesType:1,
  info: {},
  crumbs: [],
  // 想参加的人
  joinIndent: { count: 0 },
})
let state = computed(() => {
  return {
    ...pageData,
    ...pageData.info,
  }
})
const route = useRoute()
const mounted = async () => {
  const id = route.params.id
  const group = route.query.group || 1
  const path = route.path
  const typeMapper = [
    {
      //新闻
      group:'1',
      commentType: 5,
      favoritesType:5,
    },
    {
      //赛事
      group:'2',
      commentType: 6,
      favoritesType:6,
    },
    {
      //讲座
      group:'3',
      commentType: 7,
      favoritesType:7,
    }
  ]
  
  const find = typeMapper.find(item => item.group == group )
  let commentType = 3
  let favoritesType = 5;
  if( find ){
    commentType = find.commentType
    favoritesType = find.favoritesType
  }
  

  pageData.id = id
  const info = await Api.get(`/client-api/news/get?id=` + id)

  pageData.commentType = commentType
  pageData.favoritesType = favoritesType
  pageData.info = { ...info }
  rate.value = info.userStarLevel
  pageData.crumbs = [
    {
      name: info.title || info.investIntent,
    },
  ]

  const joins = await Api.get(
    `/client-api/partnership/joinIntentCount?id=${id}`
  )
  pageData.joinIndent = { ...joins }
}
mounted()
</script>
<style lang="scss" scoped>
.section-wrap {
  padding-bottom: 30px;
  .wrapper-left {
    padding-top: 30px;
  }
  .wrapper-content {
    display: flex;
    .wrapper-right {
      flex-basis: 260px;
      margin-left: 30px;
    }
    .box-suggest {
      margin-top: 20px;
    }
  }
  .wrapper-list {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
  }
  .block {
    text-align: left;
    .tag {
      height: 16px;
      border-left: 5px solid #fc9507;
      line-height: 16px;
      padding-left: 10px;
      font-weight: bold;
    }
    .cont {
      padding: 10px;
      font-size: 14px;
    }
  }
  .jysl {
    position: absolute;
    right: 0;
    width: 210px;
    top: 5px;
  }
  // 项目摘要
  .project-info {
    position: relative;
    h3 {
      font-size: 22px;
      padding-bottom: 10px;
    }

    .poster {
      height: 300px;
      img,
      video {
        height: 100%;
      }
      .wrap {
        height: 100%;
      }
    }
    .info {
      flex: 1;
      text-align: left;
      .title {
        position: relative;
      }

      .title-line {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 显示的行数 */
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5em; /* 每行的高度 */
        max-height: 3em; /* line-height * 行数 */
      }
    }

    .block {
      margin-bottom: 10px;
      ul,
      li {
        list-style: none;
        padding: 0;
        margin: 0;
      }
    }

    .block-info {
      ul,
      li {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      li {
        vertical-align: top;

        display: inline-block;
        margin-bottom: 3px;
        margin-right: 10px;
      }
      em {
        font-style: normal;
      }
      span {
        display: inline-block;
        border: 1px solid #b6daf1;
        padding: 0 4px;
        border-radius: 4px;
        background: #dbf1ff;
        color: #666;
      }
    }
    .block-info-inline {
      span {
        display: inline-block;
        border: 1px solid #b6daf1;
        padding: 0 4px;
        border-radius: 4px;
        background: #dbf1ff;
        color: #666;
      }
      li {
        vertical-align: top;
        width: auto;
        display: inline-block;
        margin-right: 10px;
      }
    }
    .block-author {
      .cont {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
      .avator {
        display: flex;
        .svg-icon {
          width: 40px;
          height: 40px;
          margin-right: 15px;
        }
        .em {
          font-size: 12px;
          color: #666;
        }
      }
      .cont-ext {
        font-size: 14px;
        .svg-icon {
          margin-left: 10px;
        }
      }
    }
  }
  .desc {
    line-height: 32px;
    font-size: 16px;
    white-space: pre-wrap;
    padding-top: 20px;
    text-align: left;
  }
  // 项目需求细节
  .project-detail {
    text-align: left;
    position: relative;
    a {
      color: #237ece;
    }

    .comment,
    .contact {
    }
    .tag-item {
      display: inline-block;
      border: 1px solid #d7d7d7;
      padding: 0 4px;
      border-radius: 4px;
      background: #f1f1f1;
      color: #666;
      margin-right: 10px;
      margin-bottom: 20px;
    }
  }
  .contact {
    .desc {
      display: flex;
      justify-content: space-between;
    }
    .svg-icon {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    .avator {
      flex: 1;
      display: flex;
      align-items: center;
    }
    .btn {
      display: inline-block;
      padding: 2px 8px;
      background-color: #ff983a;
      border-radius: 4px;
      color: white;
      margin-left: 5px;
      width: 100px;
      cursor: pointer;
      flex-basis: 66px;
      text-align: center;
      height: 25px;
      &:hover {
        background-color: #ba6210;
      }
    }
  }
}
</style>
